body {
  background-color: rgb(0, 0, 0);
}

.redbean {
  height: 100vh;
  background-image: url("/public/assets/SwoleDontPlay.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.animated-bg {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  min-height: 100vh;
}

.hidden {
  display: none !important;
}

.connect {
  position: absolute;
  top: 16px;
  right: 16px;
  color: white;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0px;
  display: inline-grid;
  text-transform: uppercase;
}

.connected {
  position: absolute;
  top: 16px;
  right: 16px;
  color: rgb(27, 238, 104);
  font-weight: 800;
  letter-spacing: 0px;
  display: inline-block;
  text-transform: uppercase;
}

.mm-button {
  background-image: url("/public/assets/mmRed.png");
  background-size: cover;
  background-color: rgba(0, 0, 0, 0);
  cursor:pointer;
  border:none;
  height: 6em;
  width: 6em;
  margin: auto;
}

.logo {
  background-image: url("/public/assets/logo1.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 223.64044944px;
  height: 64px;
  position: absolute;
  top: 16px;
  left: 496px;
}

.opensea {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.spotify {
  position: absolute;
  bottom: 16px;
  right: 100px;
}

.applemusic {
  position: absolute;
  bottom: 16px;
  right: 184px;
}

.player-container {
  opacity: 1;
  cursor: default;
  background-color: rgba(255, 255, 255, 1);
  border-bottom-color: rgba(112, 112, 112, 1);
  border-bottom-style: none;
  border-bottom-width: 1px;
  border-left-color: rgba(112, 112, 112, 1);
  border-left-style: none;
  border-left-width: 1px;
  border-right-color: rgba(112, 112, 112, 1);
  border-right-style: none;
  border-right-width: 1px;
  border-top-color: rgba(112, 112, 112, 1);
  border-top-style: none;
  border-top-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: none;
  position: absolute;
  width: 480px;
  height: 64px;
  left: 16px;
  top: 16px;
}

.album-container {
  max-width: 80px;
  top: 92px;
  left: 16px;
  position: absolute;
  display: flex;
}

.feature-container {
  position: absolute;
  bottom: 16px;
  left: 16px;
  background: white;
  padding: 5px;
  width: 300px;
}

.feature-song-text {
  color: black;
  float: right;
  font-size: 20px;
  font-weight: 500;
}

.feature-play-button {
  position: absolute;
  left: 110px;
  bottom: 20px;
  background: rgb(193, 53, 64);
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size:20px;
  font-weight: 500;
  padding: 5px 10px;
}

.fade-out {
  animation: fadeOut 5s;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { 
    opacity: 0; 
  }
}

.album-frame {
  margin-right: 16px;
}

.album {
  color: white;
  display: grid;
  text-align: center;
  font-weight: 800;
  padding: 5px;
  max-width: 80px;
  background: rgba(0, 0, 0, 0.5);
}

.active-album {
  background: rgba(193, 53, 64, 0.8);
}

.active-icon {
  color: #238ae6 !important;
}

.icon {
  color: rgba(193, 53, 64, 1);
}

.fa-dynamic {
  font-size: 2.5em;
}

.clickable {
  cursor: pointer;
}

.download-icon {
  position: absolute;
  top: 11px;
  left: 44px;
}


.play-icon {
  position: absolute;
  top: 11px;
  left: 13px;
}

.pause-icon {
  position: absolute;
  top: 11px;
  left: 13px;
}

.loop-icon {
  position: absolute;
  top: 11px;
  left: 56px;
}

.backward-icon {
  position: absolute;
  top: 11px;
  left: 380px;
}

.forward-icon {
  position: absolute;
  top: 11px;
  left: 433px;
}

.heavy {
  font-weight: 700;
}

.song-text {
  padding-top: 5px;
  letter-spacing: 0.00px;
  color: rgba(0, 0, 0, 0.7294117647058823);
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-size: 1.7em;
  font-weight: 700;
  font-style: normal;
  font-family: 'Segoe UI', Arial;
  display: inline-flex;
  width: inherit;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
}

.author-text {
  letter-spacing: 0.5px;
  display: inline-flex;
  width: inherit;
  justify-content: center;
}

.loading {
  float: right;
  margin-top: 40vh;
  margin-right: 45vw;
}

.mm-notification {
  position: absolute;
  background: rgba(46, 204, 113, 0.5);
  color: white;
  padding: 15px;
  letter-spacing: 2px;
  text-align: center;
  width: stretch;
  width: -moz-available;
  top: 40%;
}

.mint-color {
  color: rgb(0, 255, 111);
}

.mint-soldout-text {
  position: absolute;
  left: 30px;
  bottom: 96px;
  color: rgba(193, 53, 64, 1);
  font-size: 40px;
  font-weight: 800;
}

.mint-button-container {
  background-color: transparent;
  border-bottom-color: rgba(0, 255, 111, 1);
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-left-color: rgba(0, 255, 111, 1);
  border-left-style: solid;
  border-left-width: 3px;
  border-right-color: rgba(0, 255, 111, 1);
  border-right-style: solid;
  border-right-width: 3px;
  border-top-color: rgba(0, 255, 111, 1);
  border-top-style: solid;
  border-top-width: 3px;
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  border-bottom-left-radius: 44px;
  border-bottom-right-radius: 44px;
}

.minus-button {
  position: absolute;
  bottom: 92px;
  left: 16px;
  padding: 2px 4px 2px 4px;
  margin: 0 10px 0 0;
}

.mint-number {
  position: absolute;
  bottom: 96px;
  left: 54px;
  color: white;
  font-size: 18px;
  font-weight: 800;
  padding: 0 10px 0 0;
}

.plus-button {
  position: absolute;
  bottom: 92px;
  left: 74px;
  padding: 2px 4px 2px 4px;
  margin: 0 10px 0 0;
}

.mint-wrapper {
  position: absolute;
  bottom: 86px;
  left: 114px;
}

.mint-button {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: 'Segoe UI', Arial;
  color: rgb(0, 255, 111);
  background-color: transparent;
  text-align: center;
  letter-spacing: 0.00px;
  padding: 5px 40px 5px 40px;
}

.supply-text-container {
  color: white;
  font-size: 22px;
  font-weight: 700;
}

.supply-text-container > div {
  position: absolute;
  display: inline-block;
}

.supply-left {
  left: 24px;
  bottom: 54px;
}

.supply-right {
  left: 152px;
  bottom: 54px;
  text-transform: uppercase;
}

.whitelist-container {
  position: absolute;
  left: 62px;
  bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 20px 5px 20px;
  background-color: white;
}

@media only screen and (max-device-width: 480px) {
  .redbean {
    background-image: url("/public/assets/SwoleDontPlayMobile.jpeg");
  }

  .loading {
    margin-right: 40vw;
  }

  .connect {
    top: 85vh;
    right: 16px;
  }

  .player-container {
    position:absolute;
    background-color: rgba(255, 255, 255, 1);
    width: stretch;
    width: -moz-available;
    height: 70px;
    left: 0vw;
    top: 0vh;
  }

  .album-container-mobile {
    position: absolute;
    top: 0px;
    right: -1px;
  }
  
  .opensea-mobile {
    position: absolute;
    bottom: 5vh;
    left: 3vh;
  }

  .spotify-mobile {
    position: absolute;
    bottom: 5vh;
    left: 25vw;
  }

  .applemusic-mobile {
    position: absolute;
    bottom: 5vh;
    left: 45vw;
  }

  .song-text {
    padding-top: 1vh;
    color: black;
    justify-content: left;
    padding-left: 1vw;
    font-family: inherit;
    letter-spacing: 1px;
    width: inherit;
  }

  .author-text {
    letter-spacing: 0.5px;
    padding-left: 1vw;
    justify-content: left;
  }

  .fa-dynamic {
    font-size: 2em;
  }

  .active-icon {
    color: rgb(0, 255, 111) !important;
  }

  .play-icon {
    position: absolute;
    top: 75px;
    left: 43vw;
    background: rgba(236, 240, 241, 0.2);
    padding: 20px;
    border-radius: 15px;
  }
  
  .pause-icon {
    position: absolute;
    top: 75px;
    left: 43vw;
    background: rgba(236, 240, 241, 0.2);
    padding: 20px;
    border-radius: 15px;
  }

  .loop-icon {
    position: absolute;
    top: 165px;
    left: 44.5vw;
    background: rgba(236, 240, 241, 0.2);
    padding: 10px;
    border-radius: 8px;
  }
  
  .backward-icon {
    position: absolute;
    top: 85px;
    left: 27vw;
    background: rgba(236, 240, 241, 0.2);
    padding: 10px;
    border-radius: 8px;
  }
  
  .forward-icon {
    position: absolute;
    top: 85px;
    left: 62vw;
    background: rgba(236, 240, 241, 0.2);
    padding: 10px;
    border-radius: 8px;
  }

  .switch-icon {
    position: absolute;
    top: 85px;
    right: 2vw;
    background: rgba(236, 240, 241, 0.2);
    padding: 10px;
    border-radius: 8px;
  }

  .download-icon {
    position: absolute;
    top: 165px;
    left: 83vw;
    background: rgba(236, 240, 241, 0.2);
    padding: 10px;
    border-radius: 8px;
  }

  .mint-button-container {
    background-color: transparent;
    border-bottom-color: rgba(0, 255, 111, 1);
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-left-color: rgba(0, 255, 111, 1);
    border-left-style: solid;
    border-left-width: 5px;
    border-right-color: rgba(0, 255, 111, 1);
    border-right-style: solid;
    border-right-width: 5px;
    border-top-color: rgba(0, 255, 111, 1);
    border-top-style: solid;
    border-top-width: 5px;
    border-top-left-radius: 44px;
    border-top-right-radius: 44px;
    border-bottom-left-radius: 44px;
    border-bottom-right-radius: 44px;
  }

  .mint-button-container:active {
    background-color: white;
  }
  
  .minus-button {
    position: absolute;
    bottom: 16px;
    left: 56px;
    padding: 4px 6px 4px 6px;
    margin: 0 10px 0 0;
  }
  
  .mint-number {
    position: absolute;
    bottom: 70px;
    left: 65px;
    color: white;
    font-size: 25px;
    font-weight: 800;
    padding: 0 10px 0 0;
  }
  
  .plus-button {
    position: absolute;
    bottom: 112px;
    left: 56px;
    padding: 4px 6px 4px 6px;
    margin: 0 10px 0 0;
  }
  
  .mint-wrapper {
    position: absolute;
    left: 40vw;
    bottom: 100px;
  }
  
  .mint-button {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-family: 'Segoe UI', Arial;
    color: rgb(0, 255, 111);
    background-color: transparent;
    text-align: center;
    letter-spacing: 0.00px;
    padding: 15px 60px 15px 60px;
  }

  .supply-text-container {
    color: white;
    font-size: 22px;
    font-weight: 700;
  }
  
  .supply-text-container > div {
    position: absolute;
    display: inline-block;
  }
  
  .supply-left {
    left: 35vw;
    bottom: 64px;
  }
  
  .supply-right {
    left: 70vw;
    bottom: 64px;
    text-transform: uppercase;
  }
  
  .whitelist-container {
    position: absolute;
    left: 49vw;
    bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 20px 5px 20px;
    background-color: white;
  }
}